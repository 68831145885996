import React, { memo, useMemo, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Stack,
  Switch,
  Table,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import CodeBlock from '../CodeBlock';

// 寫在這邊
const fibo = n => {
  return n;
};

const Fibonacci = () => {
  console.log('Fibonacci');
  const [testLrager, setTestLrager] = useState();
  const cases = useMemo(() => {
    const base = [1, 5, 10, 20];
    if (testLrager) {
      return [...base, 50, 100];
    }
    return base;
  }, [testLrager]);

  return (
    <Stack>
      <CodeBlock>{`1. 請實作一個費波那契數列的函數
fibo(0) => 1
fibo(1) => 1
fibo(2) => 2
fibo(3) => 3
fibo(4) => 5
fibo(5) => 8
...`}</CodeBlock>
      <FormControl display="flex" alignItems="center">
        <FormLabel mb="0">試試更大的N</FormLabel>
        <Switch
          isChecked={testLrager}
          onChange={e => setTestLrager(e.target.checked)}
        />
      </FormControl>
      <Table>
        <Thead>
          <Tr>
            <Th>n</Th>
            <Th>Result</Th>
          </Tr>
        </Thead>
        {cases.map(n => (
          <Tr key={n}>
            <Td>{n}</Td>
            <Td>{fibo(n)}</Td>
          </Tr>
        ))}
      </Table>
    </Stack>
  );
};

export default memo(Fibonacci);
