import {
  Button,
  ButtonGroup,
  Center,
  IconButton,
  Stack,
} from '@chakra-ui/react';
import React, { memo, useState } from 'react';
import { MdAdd, MdRefresh } from 'react-icons/md';
import CodeBlock from '../CodeBlock';

const sample = arr => arr[Math.floor(Math.random() * arr.length)];

const heightVariants = [10, 20, 30];

const getDefaultBlocks = () =>
  Array.from({ length: 10 }, (_, i) => ({
    bg: 'gray.100',
    height: `${sample(heightVariants)}vh`,
    children: `Block ${i + 1}`,
  }));

const InsertAtBottom = () => {
  console.log('InsertAtBottom');
  const [blocks, setBlocks] = useState(getDefaultBlocks);

  const handleReset = () => {
    setBlocks(getDefaultBlocks);
  };

  const handleAddBlock = () => {
    setBlocks(prev => {
      const newBlock = {
        bg: 'yellow.50',
        height: `${sample(heightVariants)}vh`,
        children: `Block ${prev.length + 1}`,
      };
      // 請調整這裡
      return [...prev, newBlock];
    });
  };

  return (
    <Stack spacing={4} pos="relative">
      <CodeBlock>{`3. 下方有一系列的區塊，且有一個Sticky Button可以新增區塊
需求如下：當按下「新增區塊」時，即會在可視畫面(Viewport)最下方的那個區塊「之上」，插入一個新的區塊。
請你實做這個功能。`}</CodeBlock>
      <ButtonGroup pos="sticky" top="4" justifyContent="flex-end">
        <Button
          colorScheme="blue"
          leftIcon={<MdAdd />}
          onClick={handleAddBlock}
        >
          新增區塊
        </Button>
        <IconButton
          colorScheme="red"
          variant="outline"
          icon={<MdRefresh />}
          onClick={handleReset}
        />
      </ButtonGroup>
      <Stack spacing={4}>
        {blocks.map((block, index) => (
          <Center key={index} {...block} borderRadius="sm" fontWeight="bold" />
        ))}
      </Stack>
    </Stack>
  );
};

export default memo(InsertAtBottom);
