import React, { memo, useState } from 'react';
import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react';
import { MdRefresh } from 'react-icons/md';
import CodeBlock from '../CodeBlock';

// 寫在這邊
const throttle = (fn, delay = 500) => {
  return () => {
    fn();
  }
};

const Throttle = () => {
  const [counter, setCounter] = useState(0);
  const onClick = () => setCounter(c => c + 1);
  return (
    <HStack spacing={4}>
      <Text fontWeight="bold">{counter}</Text>
      <Button variant="outline" colorScheme="blue" onClick={throttle(onClick)}>
        Throttle +1
      </Button>
    </HStack>
  );
};

const ThrottleDecorator = () => {
  console.log('ThrottleDecorator');
  const [iter, setIter] = useState(0);
  return (
    <Stack spacing={4}>
      <CodeBlock>{`2. 請用原生的JS，自行撰寫throttle decorator。
下方的藍色按鈕點擊時，計數會增加1，請為他套上一個500ms的throttle decorator。

throttle的意思是：在連續點擊時，從第一次點擊後的500ms內，不管點幾次，都只算一個點擊。往後類推。`}</CodeBlock>
      <Throttle key={`th-${iter}`} />
      <Box textAlign="right">
        <Button
          leftIcon={<MdRefresh />}
          colorScheme="red"
          variant="outline"
          onClick={() => setIter(i => i + 1)}
        >
          Reset
        </Button>
      </Box>
    </Stack>
  );
};

export default memo(ThrottleDecorator);
