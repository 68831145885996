import React from 'react';
import {
  ChakraProvider,
  Box,
  Grid,
  theme,
  Container,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import Quizzes from './Quizzes';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <Container>
            <Quizzes />
          </Container>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
