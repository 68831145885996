import React from 'react';
import { Divider, Stack } from '@chakra-ui/react';

import Quiz1 from './1.fibonacci';
import Quiz2 from './2.throttle.decorator';
import Quiz3 from './3.insert-at-bottom';

const Quizzes = () => {
  return (
    <Stack
      sx={{
        '> div': {
          minH: '80vh',
          py: 8
        },
      }}
      divider={<Divider />}
    >
      <Quiz1 />
      <Quiz2 />
      <Quiz3 />
    </Stack>
  );
};

export default Quizzes;
